export const URL = {
  // dev
  //
  // API_BASE_URL: "http://192.168.0.15:8000",
  // API_BASE_URL: "https://balramagri.online",
  API_BASE_URL : "https://amptrackingapi.a2itsolution.com/",
  // API_BASE_URL: "https://grclub.jaipurcomputerclasses.com:3020",

  // apipublic

  login_admin: "/api/admin/login",
  getBettalList: "/api/admin/get_suggestion",
  users: "/api/admin/getalluser",
  getexpense: "/api/admin/getexpense",
  updateexpense: "/api/admin/updateexpense",
  driver_register: "/api/admin/createemployee",
  updateEmployee: "/api/admin/updateEmployee",
  waste_sub_category: "/api/admin/waste_sub_category",
  waste_category: "/api/admin/waste_category",
  all_category_main: "/api/admin/get_waste_category",
  get_waste_sub_category: "/api/admin/get_waste_sub_category",
  delete_waste_sub_category: "/api/admin/delete_waste_sub_category",
  update_waste_sub_category: "/api/admin/update_waste_sub_category",
  add_panchayat: "/api/admin/add_panchayat",
  get_panchayat_paginate: "/api/admin/get_panchayat_paginate",
  update_panchayat: "/api/admin/update_panchayat",
  delete_panchayat: "/api/admin/delete_panchayat",
  create_pit: "/api/admin/create_pit",
  get_pits_paginate: "/api/admin/get_pits_paginate",
  delete_pit: "/api/admin/delete_pit",
  update_pit: "/api/admin/update_pit",
  update_compost: "/api/admin/updateComposit",
  getAllUsers: "/api/admin/getAllUsers",
  getAllComposit: "/api/admin/getAllComposit",
  get_pits_export: "/api/admin/get_pits_export",
  getWasteStorageExport: "/api/admin/getWasteStorageExport",
  getAllWasteSale: "/api/admin/getAllWasteSale",

  add_donation: "/api/admin/add_donation",
  get_donation: "/api/admin/get_donation",
  delete_donation: "/api/admin/delete_donation",
  update_donation: "/api/admin/update_donation",
  user_inactive: "/api/admin/activeInactive",
  deleteEmployee: "/api/admin/deleteEmployee",
  updateStoreWaste: "/api/admin/updateStoreWaste",
  updateSaleWaste: "/api/admin/updateSaleWaste",
  updateWasteInPit: "/api/admin/updateWasteInPit",

  getSlider: "/api/admin/getSlider",
  deleteSlider: "/api/admin/deleteSlider",
  addSlider: "/api/admin/addSlider",

  addContactUs: "/api/admin/addContactUs",
  getContactUs: "/api/admin/getContactUs",

  add_composit: "/api/admin/add_composit",
  get_composit_history: "/api/admin/get_composit_history",

  add_spendature: "/api/admin/add_spendature",
  get_spendature: "/api/admin/get_spendature",
  delete_expenditure: "/api/admin/delete_expentidure",
  update_expentidure: "/api/admin/update_expentidure",

  addWasteInPit: "/api/admin/addWasteInPit",
  getPitWasteHistory: "/api/admin/getPitWasteHistory",
  deletePitWasteHistory: "/api/admin/deletePitWasteHistory",

  sale_waste: "/api/admin/sale_waste",
  getWasteSale: "/api/admin/getWasteSale",
  getPitWiseHistory: "/api/admin/getPitWiseHistory",

  store_waste: "/api/admin/store_waste",
  getWasteStorageHistory: "/api/admin/getWasteStorageHistory",
  dashboard: "/api/admin/dashboard",
  makeDoner: "/api/admin/makeDoner",
  getProfileDetails: "/api/admin/getDriverLocation",
  //

  getempexpense: "/api/admin/getempexpense",
  getempattendance: "/api/admin/getempattendance",
  getTaskEmpwise: "/api/admin/getTaskEmpwise",
  user_contact: "/api/admin/getlocationdata",
  all_category: "/api/all_category",

  delete_category: "/api/delete_category",
  update_category: "/api/update_category",
  create_quiz_category: "/api/create_quiz_category",
  quiz_list: "/api/quiz_list",
  create_quiz: "/api/create_quiz",
  delete_quiz: "/api/delete_quiz",
  update_quiz: "/api/update_quiz",
  questions_list: "/api/questions_list",
  delete_question: "/api/delete_question",
  update_question: "/api/update_question",
  create_quiz_question: "/api/create_quiz_question",

  user_allcontact: "/api/user_allcontact",

  quiz_active: "/api/quiz_active",

  addFoundByAdmin: "/adminApi/addFoundByAdmin",

  deductFoundByAdmin: "/adminApi/deductFoundByAdmin",
  getUserListseacrch: "/adminApi/getUserListseacrch",
  deleteByAdminBettal: "/adminApi/deleteByAdminBettal",
  getBettalListAdmin: "/adminApi/getBettalListAdmin",
  getBettalListAdminFilter: "/adminApi/getBettalListAdminFilter",
  getwithdrwarlAdminList: "/adminApi/getwithdrwarlAdminList",
  completeWithdrawalReq: "/adminApi/completeWithdrawalReq",
  getkycpending: "/adminApi/getkycpending",
  getTransactionList: "/adminApi/getTransactionList",

  getbDetails: "/adminApi/getbDetails",

  dashboardChart: "/adminApi/dashboardChart",
  getsetting: "/adminApi/getsetting",
  createSetting: "/adminApi/createSetting",
  createmessage: "/adminApi/createmessage",
  messageList: "/adminApi/messageList",
  messagedelete: "/adminApi/messagedelete",
  submitwinnerAdmincencel: "/adminApi/submitwinnerAdmincencel",
  submitwinnerAdmincencelCron: "/adminApi/submitwinnerAdmincencelCron",
  getsearchBettalList: "/adminApi/getsearchBettalList",

  verifykeyByAdmin: "/adminApi/verifykeyByAdmin",
  submitwinnerAdmin: "/adminApi/submitwinnerAdmin",
  createaccountList: "/adminApi/createaccountList",
  getaccountList: "/adminApi/getaccountList",
  deleteaccountList: "/adminApi/deleteaccountList",
  acctiveaccountlist: "/adminApi/acctiveaccountlist",
  addfundreqestListAdmin: "/adminApi/addfundreqestListAdmin",
  addsearchfundreqestListAdmin: "/adminApi/addsearchfundreqestListAdmin",
  addfundreqestUpdateAdmin: "/adminApi/addfundreqestUpdateAdmin",
  getsearchTransactionList: "/adminApi/getsearchTransactionList",
  getsearchwithdrwarlAdminList: "/adminApi/getsearchwithdrwarlAdminList",
  getnotificationAdminall: "/adminApi/getnotificationAdminall",
  getnotificationAdminViewall: "/adminApi/getnotificationAdminViewall",

  createcategories: "/apiVendor/createcategories",
  getCategory: "/publicApi/getCategory",
  getsubsubcate: "/publicApi/getsubsubcate",
  getsubsubcateHome: "/publicApi/getsubsubcateHome",
  signupSeller: "/publicApi/signupSeller",
  otpVerify: "/publicApi/otpVerify",
  productDetails: "/publicApi/productDetails",
  getsellerProductListPublice: "/publicApi/getsellerProductListPublice",
  gettagsProductListPublice: "/publicApi/gettagsProductListPublice",
  getsellerProductListPublicefilCate:
    "/publicApi/getsellerProductListPublicefilCate",
  getsellerProductListPublicebycategory:
    "/publicApi/getsellerProductListPublicebycategory",
  getsellerprofile: "/publicApi/getsellerprofile",
  whatsappinquiresubmit: "/publicApi/whatsappinquiresubmit",
  getsubsubCategoryList: "/publicApi/getsubsubCategoryList",
  createSubscription: "/publicApi/createSubscription",
  getPages: "/publicApi/getPagesPu",
  emailenquirysubmit: "/publicApi/emailenquirysubmit",
  pageDetails: "/publicApi/pageDetails",
  searchProductpub: "/publicApi/searchProductpub",
  sendOtpSeller: "/publicApi/sendOtpSeller",
  otpVerifyseller: "/publicApi/otpVerifyseller",
  signinSellereSecurite: "/publicApi/signinSellereSecurite",
  footerPagepageListetPu: "/publicApi/footerPagepageListetPu",
  productclick: "/publicApi/productclick",
  getHomePro: "/publicApi/getHomePro",
  getSellerListPublic: "/publicApi/getSellerListPublic",
  getsellerProductListPublicebymaincategory2:
    "/publicApi/getsellerProductListPublicebymaincategory2",

  // apiAdmin
  signinSeller: "/publicApi/signinSeller",
  getsellerProductList: "/sellerApi/getsellerProductList",
  createSellerProduct: "/sellerApi/createSellerProduct",
  searchSellerProduct: "/sellerApi/searchSellerProduct",
  deleteSellerProduct: "/sellerApi/deleteSellerProduct",
  createBussnissPro: "/sellerApi/createBussnissPro",
  getBussProfile: "/sellerApi/getBussProfile",
  updateWhatsaapNumber: "/sellerApi/updateWhatsaapNumber",
  verifyWhatsapp: "/sellerApi/verifyWhatsapp",
  updateBussLogo: "/sellerApi/updateBussLogo",
  updateBussProfile: "/sellerApi/updateBussProfile",
  getPackageList: "/sellerApi/getPackageList",
  createPurchase: "/sellerApi/createPurchase",
  getsellerTransaction: "/sellerApi/getsellerTransaction",
  getprofile: "/sellerApi/getprofile",
  updatesellerProduct: "/sellerApi/updatesellerProduct",
  getSellerTicketList: "/sellerApi/getSellerTicketList",
  createSellerTickets: "/sellerApi/createSellerTickets",
  getmessageSupportList: "/sellerApi/getmessageSupportList",
  createMessage: "/sellerApi/createMessage",
  deleteacountByseller: "/sellerApi/deleteacountByseller",
  copyProduct: "/sellerApi/copyProduct",
  getInvoice: "/sellerApi/getInvoice",
  getEmailenquirySeller: "/sellerApi/getEmailenquirySeller",
  getsellerWhatsappinquire: "/sellerApi/getsellerWhatsappinquire",
  searchtags: "/sellerApi/searchtags",
  passwordChange: "/sellerApi/passwordChange",
  topViewnewSeller: "/sellerApi/topViewnewSeller",
  sellerDashboardState: "/sellerApi/sellerDashboardState",
  getExpireDate: "/sellerApi/getExpireDate",
  getsliderList: "/publicApi/getsliderList2",
  getBlogListPub: "/publicApi/getBlogListPub",
  getBlogDetails: "/publicApi/getBlogDetails",
  gethomePop: "/publicApi/gethomePop",
  getlistfaqs: "/publicApi/getlistfaqs",
  getInvoceDetails: "/publicApi/getInvoceDetails",
};
